import React, { useContext, useRef, useState } from "react"
import { graphql } from "gatsby"
import Layout from "../components/Layout"
import SEO from "../components/SEO"
import {
  Container,
  Box,
  SimpleGrid,
  FormControl,
  Input,
  Textarea,
  Select,
} from '@chakra-ui/react'
import { H1 } from '../theme/Headings'
import { spacing, colors, breakpoints } from '../theme/themeAlopex'
// import BackgroundImage from 'gatsby-background-image'
import ColorContext from '../context/ColorContext'

const ApplicationPage = ({ data }) => {
  const { color } = useContext(ColorContext)
  const refFile = useRef(null)
  const [ fName, setFName ] = useState('Please attach your resume')

  const handleFilename = () => {
    let fileName = refFile.current.value.split('\\')
    setFName(fileName[fileName.length - 1])
  }

  return (
    <Layout>
      <SEO title={`Employment Application ${data.site.siteMetadata.title}`} />
      <Box backgroundColor={color.alpha}>
        <Container maxWidth={breakpoints.xl} pt={spacing.header} pb={spacing.section} color={colors.brand.white}>
          <H1 variant="pageHeader" color={colors.brand.white} mb={spacing.groups}>Nice to Meet You!</H1>
          <form name="Application" method="POST" data-netlify="true" netlify>
            <SimpleGrid columns={[1, null, 2]} gap="10px" mb="10px" sx={{ 'input[type="text"], select, textarea': { minHeight: '55px', color: color.alpha } }}>
              <FormControl id="name" isRequired>
                <Input type="text" name="name" placeholder="Name" borderRadius="0" backgroundColor={colors.brand.lightGray} _placeholder={{color: color.alpha}} color={color.alpha} />
              </FormControl>
              <FormControl id="location">
                <Input type="text" name="Location" placeholder="Where in the world are you?" borderRadius="0" backgroundColor={colors.brand.lightGray} _placeholder={{color: color.alpha}} color={color.alpha} />
              </FormControl>
              <FormControl id="email" isRequired>
                <Input name="Email" type="email" placeholder="Email" borderRadius="0" backgroundColor={colors.brand.lightGray} _placeholder={{color: color.alpha}} color={color.alpha} />
              </FormControl>
              <FormControl id="phone" isRequired>
                <Input name="Phone" type="tel" placeholder="Phone" borderRadius="0" backgroundColor={colors.brand.lightGray} _placeholder={{color: color.alpha}} color={color.alpha} />
              </FormControl>
              <FormControl id="specialty" isRequired>
                <Input name="Specialty" placeholder="What is your specialty?" borderRadius="0" backgroundColor={colors.brand.lightGray} _placeholder={{color: color.alpha}} color={color.alpha} />
              </FormControl>
              <FormControl id="experience" isRequired>
                <Input name="Experience" placeholder="Years of experience?" borderRadius="0" backgroundColor={colors.brand.lightGray} _placeholder={{color: color.alpha}} color={color.alpha} />
              </FormControl>
            </SimpleGrid>
            <Textarea name="Inquiry" display="block" mb="10px" placeholder="Inquiry" borderRadius="0" height="200px" backgroundColor={colors.brand.lightGray} _placeholder={{color: color.alpha}} color={color.alpha} />
            
            <input ref={refFile} type="file" className="inputFile" id="resume" name="resume" onChange={handleFilename} />
            <label htmlFor="resume">{fName}</label>

            <SimpleGrid columns={[1, null, 2]} gap="10px" mt="10px">
              <FormControl id="howhear" isRequired>
                <Select name="How hear" placeholder="How did you hear about us?" minHeight="55px" borderRadius="0" backgroundColor={colors.brand.lightGray} _placeholder={{color: color.alpha}} color={color.alpha}>
                  <option>Word of Mouth</option>
                  <option>Search</option>
                  <option>Advertisement</option>
                </Select>
              </FormControl>
              <Input
                type="submit"
                value="Send"
                _hover={{
                  color: color.alpha,
                }}
              />
            </SimpleGrid>
            <input type="hidden" name="form-name" value="Application" />
          </form>
        </Container>
      </Box>
    </Layout>
  )
}

export const query = graphql`
  query aloApplication {
    site {
      siteMetadata {
        title
      }
    }
  }
`

export default ApplicationPage
